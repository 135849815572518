// App.tsx

import React from 'react';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>B&BY.</h1>
        <p>Coming soon</p>
        {/* You can remove or modify the logo and other elements as needed */}
      </header>
    </div>
  );
};

export default App;
